import * as React from 'react'
import { inject,  observer } from 'mobx-react'
import { Link } from 'react-router-dom'
import { RouteManager } from '@festival/route-manager'
import { Activity } from '@festival/activity/store'
import ImageMedia from '@festival/component/image-media'
import {
    Card, CardBody, CardTitle
} from 'reactstrap'
import TagList from '@festival/tag/list'
import VersionToggler from '@festival/versionable/version-toggler'

export interface Props {
    activity: Activity
    routeManager?: RouteManager
}

export interface State {

}

export class ListItem extends React.Component<Props, State> {
    render (): React.ReactNode {
        const { activity, routeManager } = this.props

        if (!routeManager) {
            return null
        }

        return (
            <Card className="border-0">
                <VersionToggler container={activity} className="position-absolute" style={{ top: 0 }}/>
                <Link to={routeManager.generate('activity-show', {params: {slug: activity.active.slug}})}>
                    <ImageMedia media={activity.active.media} name="sticker" version="large" className="border border-primary" alt={activity.active.altSticker}/>
                </Link>
                <CardBody className="p-1">
                    <CardTitle tag="h6" className="text-uppercase mb-0 text-truncate">
                        <Link
                            to={routeManager.generate('activity-show', {params: {slug: activity.active.slug}})}
                            className=" text-primary"
                            >
                            { activity.active.title }
                        </Link>
                    </CardTitle>
                    <TagList
                        type="activity"
                        codes={activity.active.tags}
                        root={routeManager.generate('activities')}
                        excludeGroupCodes={['day']}
                        />
                </CardBody>
            </Card>
        )
    }
}

export default inject('routeManager')(observer(ListItem))