import * as React from 'react'
import * as Base from 'react-festival/build/activity/show'
import { inject } from 'mobx-react'
import { FormattedMessage } from 'react-intl'
import { RouteManager } from '@festival/route-manager'
import { withRouter } from 'react-router-dom'
import { ActivityComplete } from '@festival/activity/store'
import ImageMedia from '@festival/component/image-media'
import TagList from './tag-list'
import GuestLink from '@festival/activity/guest-link'
import { Row, Col, Button } from 'reactstrap'
import DayList from './day-list'
import { FacebookShareButton, TwitterShareButton } from 'react-share'
import VersionToggler from '@festival/versionable/version-toggler'
import { PageConfigurationComplete } from '@festival/page-configuration/store'
import { PageActivities } from '@festival/entities/page-configuration'

export class Show extends Base.Show {

    protected renderContent (activity: ActivityComplete, routeManager: RouteManager, configuration: PageConfigurationComplete<PageActivities>): React.ReactNode {

        const title = activity.active.title + (configuration.details ? ' - ' + configuration.details.showTitle : '')

        return (
            <>
                <Row>
                    <Col xs={12} lg={9}>
                        <VersionToggler container={activity} className="position-absolute" style={{ top: 0 }}/>
                        <ImageMedia media={activity.active.media} name="cover" version="large" className="border border-primary" alt={activity.active.altCover}/>
                        <h3 className="text-primary d-flex justify-content-between mt-2">
                            <span className="text-uppercase">
                                <i className="mdi mdi-pound"></i>
                                { activity.active.title }
                            </span>

                            <DayList
                                className="d-flex"
                                type="activity"
                                codes={activity.active.tags}
                                root={routeManager.generate('activities')}
                                groups={['day']}
                            />
                        </h3>

                        <div
                            className="description text-primary"
                            dangerouslySetInnerHTML={{ __html: activity.active.description }}
                            ></div>
                    </Col>
                    <Col xs={12} lg={3}>
                        <TagList
                            type="activity"
                            codes={activity.active.tags}
                            root={routeManager.generate('activities')}
                            excludeGroupCodes={['day']}
                            />

                        <div className="social-share">
                            <h6 className="text-uppercase text-primary text-center mt-2">
                                <FormattedMessage id="activity.show.social-share" />
                            </h6>

                            <div className="d-flex justify-content-around flex-lg-column justify-content-sm-start">
                                <Button
                                    tag={TwitterShareButton}
                                    resetButtonStyle={false}
                                    url={(this.props.routeManager ? this.props.routeManager.root : '') + this.props.location.pathname}
                                    size="sm"
                                    className="btn-twitter text-nowrap mr-2 mr-lg-0 mb-lg-2"
                                    title={title}
                                >
                                    <i className="mdi mdi-twitter mr-2"></i>
                                    <FormattedMessage id="activity.show.social-share.twitter" />
                                </Button>
                                <Button
                                    tag={FacebookShareButton}
                                    resetButtonStyle={false}
                                    url={(this.props.routeManager ? this.props.routeManager.root : '') + this.props.location.pathname}
                                    size="sm"
                                    className="btn-facebook text-nowrap"
                                    quote={title}
                                >
                                    <i className="mdi mdi-facebook mr-2"></i>
                                    <FormattedMessage id="activity.show.social-share.facebook" />
                                </Button>
                            </div>
                        </div>

                        { activity.active.participations.length > 0 && (
                            <div className="activity-participants mt-4">
                                <h6 className="text-uppercase text-primary text-center">
                                    <FormattedMessage id="activity.show.participations" />
                                </h6>
                                <Row>
                                    {activity.active.participations.map((uuid: string, index: number) => (
                                        <GuestLink key={index} uuid={uuid} />
                                    ))}
                                </Row>
                            </div>
                        )}
                    </Col>
                </Row>
            </>
        )
    }
}

export default withRouter(inject('routeManager', 'activityStore', 'pageConfigurationStore')(Show))
