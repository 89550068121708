import * as React from 'react'
import * as Base from 'react-festival/build/activity/guest-link'
import { inject } from 'mobx-react'
import { Link } from 'react-router-dom'
import { RouteManager } from '@festival/route-manager'
import ImageMedia from '@festival/component/image-media'
import { Guest } from '@festival/guest/store'
import {
    Col, Card, CardBody, CardTitle
} from 'reactstrap'

export class GuestLink extends Base.GuestLink {

    renderContent (guest: Guest, routeManager: RouteManager): React.ReactNode {
        return (
            <Col lg={12} sm={4} xs={6}>
                <Card className="border-0">
                    <Link to={routeManager.generate('guest-show', {params: {slug: guest.active.slug}})}>
                        <ImageMedia media={guest.active.media} name="sticker" version="large" className="border border-primary" alt={guest.active.altSticker}/>
                    </Link>
                    <CardBody className="p-1">
                        <Link to={routeManager.generate('guest-show', {params: {slug: guest.active.slug}})}>
                            <CardTitle tag="h6" className="text-primary text-truncate">{ guest.active.name }</CardTitle>
                        </Link>
                    </CardBody>
                </Card>
            </Col>
        )
    }
}

export default inject('routeManager', 'guestStore')(GuestLink)
