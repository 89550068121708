import * as React from 'react'
import { List } from 'react-festival/build/tag/list'
import { inject } from 'mobx-react'
import { Badge } from 'reactstrap'
import { Tag } from '@festival/tag/store'
import { TagGroup } from '@festival/entities/tag-group'
import cn from 'classnames'
import { Store as EditionStore } from '@festival/edition/store'
import { format, addDays } from 'date-fns'
import fr from 'date-fns/locale/fr'

export class DayList extends List {

    getItemClass (tag: Tag, group: TagGroup): string {
        return cn('mx-1', 'tag', 'tag-day', 'tag-day-'+tag.code)
    }

    getItemWrapperClass (tag: Tag, group: TagGroup): string {
        return cn('tag-wrapper-day', 'mx-0', 'd-inline-block', 'px-1')
    }

    renderContent (tag: Tag, group: TagGroup, extra: Extra): React.ReactNode {
        return (
            <div>
                <span className="tag-day-label">
                    { tag.label }
                </span>
                <span className="tag-day-dayOfMonth">
                    { extra.dayOfMonth }
                </span>
            </div>
        )
    }

    getExtra (tag: Tag, group: TagGroup): Extra {
        const { editionStore } = this.props

        if (!editionStore) {
            return {
                dayOfMonth: ''
            }
        }

        process.env.TZ = 'Europe/Paris'
        const extra: any = {}
        const nbDays = editionStore.current.nbDays
        for (let i = 0; i < nbDays; i++) {
            const d = addDays(editionStore.current.referenceDate, i)

            if (format(d, 'iiii', { locale: fr }).toLowerCase() === tag.code) {
                extra.dayOfMonth = format(d, 'd')
            }
        }

        return extra
    }
}

export default inject('tagStore', 'editionStore')(DayList)
